<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo mt-4 mb-4">
          <img
            :src="style.loginImage"
            width="150px"
          >
        </b-link>

        <b-card-text class="mb-2">
          {{ $t('Por favor, faça o login na sua conta.') }}
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          v-slot="{ invalid }"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <!-- email -->
            <b-form-group
              label-for="email"
              :label="$t('Usuário')"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('Usuário')"
                rules="required"
              >
                <b-form-input
                  id="email"
                  v-model="userName"
                  v-keyboard="{ layout: 'default' }"
                  name="username"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('Usuário')"
                  autofocus
                  tabindex="1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <!-- <div class="d-flex justify-content-between">
                <label for="password">{{ $t('Senha') }}</label>
                <b-link :to="{ name: 'auth-forgot-password' }">
                  <small>{{ $t('Esqueceu a senha?') }}</small>
                </b-link>
              </div> -->
              <validation-provider
                v-slot="{ errors }"
                :name="$t('Senha')"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    v-keyboard="{ layout: 'default' }"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="password"
                    :placeholder="$t('Senha')"
                    tabindex="2"
                    data-private
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <e-button
              data-test="LoginSubmitButton"
              type="submit"
              variant="primary"
              class="mr-1"
              block
              :text="$t('Entrar')"
              :busy="busy"
              :disabled="invalid"
              @click="login"
            />
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import jwt from 'jsonwebtoken'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { EButton } from '@/views/components'
import { acl, app } from '@/mixins'
import { required, email } from '@validations'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { togglePasswordVisibility } from '@/@core/mixins/ui/forms'

export default {
  components: {
    EButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility, acl, app],
  data() {
    return {
      userName: '',
      password: '',
      status: '',
      operator: '',
      hubs: [],
      // validation rules
      required,
      email,
      busy: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  async mounted() {
    if (this.$router.currentRoute.params.sessionExpired) {
      this.showWarning({
        title: this.$t('Sessão expirada'),
        message: this.$t('Para sua segurança, faça o login novamente.'),
      })
    }
  },
  methods: {
    async login() {
      if (await this.$refs.loginForm.validate()) {
        this.busy = true
        useJwt
          .login({
            userName: this.userName,
            password: this.password,
          })
          .then(response => {
            const {
              // eslint-disable-next-line no-shadow
              email,
              userName,
              token,
              permissions,
            } = response.data

            const userDecoded = jwt.decode(token)

            const userData = {
              email,
              userName,
              fullName: userName,
              userId: userDecoded.nameid,
            }

            useJwt.setToken(token)
            useJwt.setRefreshToken(null)
            this.$store.dispatch('auth/setUser', userData)
            this.$store.dispatch('auth/setPermissions', permissions)
            this.$store.dispatch('app/fetchCombos')
            this.configureAbility()
            const destinationRouter = this.$router.currentRoute.params.redirect
              ? this.$router.currentRoute.params.redirect
              : getHomeRouteForLoggedInUser()
            this.$router.replace(destinationRouter).catch(error => {
              this.showError({ error })
            })
          })
          .catch(error => {
            this.showError({
              title: this.$t('Autenticação'),
              error,
            })
          })
          .finally(() => {
            this.busy = false
          })
        // try {
        // const response = await useJwt.login({
        //   userName: this.userName,
        //   password: this.password,
        // })
        // debugger
        // const {
        //   // eslint-disable-next-line no-shadow
        //   email,
        //   userName,
        //   token,
        //   permissions,
        // } = response.data
        // const userData = {
        //   email,
        //   userName,
        //   fullName: userName,
        // }
        // useJwt.setToken(token)
        // useJwt.setRefreshToken(null)
        // this.$store.dispatch('auth/setUser', userData)
        // this.$store.dispatch('auth/setPermissions', permissions)
        // this.configureAbility()
        // const destinationRouter = this.$router.currentRoute.params.redirect
        //   ? this.$router.currentRoute.params.redirect : getHomeRouteForLoggedInUser()
        // this.$router.replace(destinationRouter)
        //   .catch(error => {
        //     this.showError({ error })
        //   })
        // } catch (error) {
        //   debugger
        //   this.showError({
        //     message: error.response ? this.$t(error.response.data.message) : this.$t('Não foi possível realizar o login'),
        //   })
        // } finally {
        //   debugger
        //   this.busy = false
        // }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
